import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LocalizeText } from "components/localizer";
import { Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";
import { usePermission } from "utils/hooks/usePermission";

export interface IPageTab {
    handle: string;
    tag: TLanguageTag;
    content: ReactNode;
    requiredPerm?: string;
}

interface IProps {
    titleTag: TLanguageTag;
    overTitleContent?: ReactNode;
    overTabsContent?: ReactNode;
    rightSideContent?: ReactNode;
    tabs: IPageTab[];
}

export const PageWithTabs: FC<IProps> = ({ titleTag, overTitleContent, overTabsContent, rightSideContent, tabs }) => {
    const history = useHistory();
    const params = useParams<{ tab: string }>();
    const [currentTab, setCurrentTab] = useState(tabs[0].handle);
    const { hasPermission } = usePermission();

    const getBaseUrl = useCallback(() => {
        const parts = history.location.pathname.split("/");
        let baseUrl = history.location.pathname;
        for (const tab of tabs) {
            const foundIndex = parts.indexOf(tab.handle);
            if (foundIndex !== -1) {
                baseUrl = parts.slice(0, foundIndex).join("/");
                break;
            }
        }
        return baseUrl;
    }, [tabs, history.location.pathname]);

    useEffect(() => {
        if (currentTab === params.tab) {
            return;
        }

        const newTab = tabs.find((tab) => tab.handle === params.tab) ? params.tab : tabs[0].handle;
        setCurrentTab(newTab);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.tab]);

    const onChangeTab = useCallback(
        (newTab: string) => {
            setCurrentTab(newTab);
            history.push(`${getBaseUrl()}/${newTab}`);
        },
        [history, getBaseUrl]
    );

    const validTabs = useMemo(
        (): IPageTab[] => tabs.filter(({ requiredPerm }) => !requiredPerm || hasPermission(requiredPerm)),
        [tabs, hasPermission]
    );

    return (
        <div className="contentWrapper">
            <TabContext value={currentTab}>
                <Grid container direction="column">
                    {overTitleContent ? <Grid item>{overTitleContent}</Grid> : null}
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1" fontSize="2rem" fontFamily="Vasakronan" marginBottom="10px">
                                <LocalizeText tag={titleTag} />
                            </Typography>
                        </Grid>
                        {rightSideContent ? <Grid item>{rightSideContent}</Grid> : null}
                    </Grid>
                    {overTabsContent ? <Grid item>{overTabsContent}</Grid> : null}
                    <Grid item>
                        <TabList onChange={(_: any, newTab: string) => onChangeTab(newTab)}>
                            {validTabs.map((tab) => {
                                return (
                                    <Tab key={tab.handle} label={<LocalizeText tag={tab.tag} />} value={tab.handle} />
                                );
                            })}
                        </TabList>
                        {validTabs.map((tab) => (
                            <TabPanel key={tab.handle} value={tab.handle} sx={{ backgroundColor: colors.white }}>
                                {tab.content}
                            </TabPanel>
                        ))}
                    </Grid>
                </Grid>
            </TabContext>
        </div>
    );
};

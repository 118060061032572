import React, { ChangeEvent, FC, ReactElement, useContext, useEffect, useState } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { ITask, ITaskAction } from "reducers/realestates";
import { fontType, LocalizeText } from "components/localizer";
import { THeadCell, TListOrder } from "utils/types";
import Banner from "components/banner";
import { Sort, FiberManualRecord, Add, PriorityHighRounded } from "@mui/icons-material";
import {
    Grid,
    Divider,
    Typography,
    Tab,
    Tabs,
    MenuItem,
    TextField,
    InputAdornment,
    Box,
    FormControlLabel,
    Switch,
    Tooltip,
    IconButton,
} from "@mui/material";
import VKButton from "components/vkButton";
import TopBar from "components/tableComponents/topBar";
import CreateTaskForm from "components/tasks/createTaskForm";
import TaskComment from "components/tasks/taskComment";
import CreateTaskActionDialog from "components/tasks/createTaskActionDialog";
import { ProfileContext } from "contexts/profile";
import { compareAsc, compareDesc, format } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import { dateFormats } from "utils/formats";
import { getLanguageLocale } from "utils/helpers";
import { ModalContext } from "contexts/modals";
import { usePermission } from "utils/hooks/usePermission";
import colors from "styles/colors";
import StyledSwitchContainer from "components/tableComponents/switchContainer";
import { usePaginationStore } from "utils/usePaginationStore";

export const headCells: THeadCell[] = [
    { id: "status", sortId: "status", label: <LocalizeText tag="status" /> },
    { id: "reported_time", sortId: "registration_time", label: <LocalizeText tag="timeReported" /> },
    { id: "reporter_name", sortId: "reported_by", label: <LocalizeText tag="reportedBy" /> },
    { id: "real_estate", sortId: "cadastral_name", label: <LocalizeText tag="realEstate" /> },
    { id: "property", sortId: "premises__agreement_number", label: <LocalizeText tag="property" /> },
    { id: "category", sortId: "category_name", label: <LocalizeText tag="category" /> },
    { id: "task_id", sortId: "task_number", label: <LocalizeText tag="taskNumber" /> },
    { id: "description", label: <LocalizeText tag="description" /> },
];

type TStatus = "Kvitterad" | "Delkvitterad" | "Ej påbörjad";

interface TasksCollapseProps {
    task: ITask;
}

const TasksCollapse: FC<TasksCollapseProps> = ({ task }) => {
    const queryTab = new URLSearchParams(useLocation().search).get("tab") as TaskTab;
    const { pushDialog } = useContext(ModalContext);
    const [commentSortDirection, setCommentSortDirection] = useState<TListOrder>("desc");

    const sortComments = (action1: ITaskAction, action2: ITaskAction): number => {
        const dateOfAction1 = Date.parse(action1.registration_time);
        const dateOfAction2 = Date.parse(action2.registration_time);
        if (commentSortDirection === "asc") {
            return compareAsc(dateOfAction1, dateOfAction2);
        }
        return compareDesc(dateOfAction1, dateOfAction2);
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
                <Typography variant="body1" gutterBottom paragraph display="inline">
                    {task.description}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography gutterBottom paragraph>
                    <LocalizeText
                        type={fontType.BOLD}
                        tag="reportedByAtDate"
                        replace={{
                            name: task.reported_by,
                            date: format(new Date(task.registration_time), dateFormats.LOCALIZED_DATE, {
                                locale: getLanguageLocale(),
                            }),
                            time: format(new Date(task.registration_time), dateFormats.WEBTIME),
                        }}
                    />
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="h6" sx={{ color: colors.vkBlue }}>
                    <LocalizeText tag="comments" />
                </Typography>
            </Grid>
            <Grid container item xs={4} justifyContent="flex-end" style={{ marginBottom: "2px" }}>
                {queryTab === "MY" && (
                    <VKButton
                        size="small"
                        tag="createTaskAction"
                        template="primary"
                        leftAddon={<Add fontSize="inherit" />}
                        sx={{ margin: "0 1rem" }}
                        onClick={() => pushDialog(CreateTaskActionDialog, { taskNumber: parseInt(task.task_number) })}
                    />
                )}
                <TextField
                    onChange={(e) => setCommentSortDirection(e.target.value as TListOrder)}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Sort sx={{ color: colors.vkBlue }} />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                    id="select"
                    sx={{ color: colors.vkBlue, backgroundColor: colors.white }}
                    value={commentSortDirection}
                    select
                >
                    <MenuItem value="asc">
                        <LocalizeText tag="sortDateAsc" />
                    </MenuItem>
                    <MenuItem value="desc">
                        <LocalizeText tag="sortDateDesc" />
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                {task.task_actions?.length ? (
                    task.task_actions?.sort(sortComments).map((action) => {
                        return (
                            <div key={action.registration_time}>
                                <Divider />
                                <TaskComment action={action} />
                            </div>
                        );
                    })
                ) : (
                    <>
                        <Divider />
                        <Typography gutterBottom={true}>
                            <LocalizeText tag="noDataAvailable" />
                        </Typography>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

type TaskTab = "MY" | "ORG";

const TasksView: FC = () => {
    const { user, selectedOrganization } = useContext(ProfileContext);
    const search = useLocation().search;
    const history = useHistory();
    const queryTab = new URLSearchParams(search).get("tab") as TaskTab;

    const [tab, setTab] = useState<TaskTab>(queryTab ?? "MY");
    const { pushDialog } = useContext(ModalContext);
    const [includeCompleted, setIncludeCompleted] = useState<boolean>(false);
    const { hasPermission } = usePermission();



    useEffect(() => {
        if (queryTab) {
            setTab(queryTab);
        } else {
            history.replace({
                pathname: "/tasks",
                search: "?tab=MY",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, queryTab]);

    const tasksPagination = usePaginationStore("tasks", undefined, {
        filter: tab.toLowerCase(),
        org_nr: selectedOrganization.org_nr,
        exclude_completed: !includeCompleted,
    });

    const changeTab = (newTab: TaskTab): void => {
        if (newTab !== tab) {
            history.replace({
                pathname: "/tasks",
                search: `?tab=${newTab}`,
            });

            tasksPagination.setParam("filter", newTab.toLowerCase());
        }
    };

    const getStatusIcon = (status: TStatus): ReactElement => {
        let color = colors.errorRed;
        switch (status) {
            case "Kvitterad":
                color = colors.successGreen;
                break;
            case "Delkvitterad":
                color = colors.vkBlue;
                break;
            case "Ej påbörjad":
            default:
                break;
        }
        return <FiberManualRecord sx={{ color: color }} />;
    };

    const mapRows = (): IRow[] => {
        return tasksPagination.results.map((task: ITask) => ({
            data: [
                <Tooltip key={`task-row_${task.task_uuid}_status`} title={task.status_name}>
                    {getStatusIcon(task.status_name as TStatus)}
                </Tooltip>,
                format(new Date(task.registration_time), dateFormats.WEBDATE),
                task.reported_by.substring(0, 20).concat("...") || " - ",
                task.cadastral_name || task.premises.cadastral_name || " - ",
                task.premises ? task.premises.agreement_number : " - ",
                task.category_name.substring(0, 20).concat("...") || " - ",
                task.task_number || " - ",
                task.description.substring(0, 20).concat("..."),
            ],
            collapsibleContent: <TasksCollapse task={task} />,
        }));
    };

    const onIncludeCompletedChange = (event: ChangeEvent<HTMLInputElement>): void => {
        tasksPagination.setParam("exclude_completed", !event.target.checked);
        setIncludeCompleted(event.target.checked);
    };

    return (
        <div data-testid="tasksView-root" className="contentWrapper">
            <Banner
                title="tasks"
                subTitle="bannerTasks"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/tasks-1024x683.jpg`}
                imageStyle={{ objectPosition: "50% 90%" }}
            />
            <TopBar
                singleItem={
                    user &&
                    !user.is_staff &&
                    hasPermission("add_task") && (
                        <VKButton
                            size="small"
                            variant="outlined"
                            tag="createTask"
                            onClick={() => pushDialog(CreateTaskForm, {})}
                        />
                    )
                }
            />
            <Box>
                <Tabs value={tab} onChange={(event, value) => changeTab(value)}>
                    <Tab label={<LocalizeText tag="myTasks" />} value="MY" />
                    <Tab label={<LocalizeText tag="allTasksInOrg" />} value="ORG" />
                </Tabs>

                <StyledSwitchContainer>
                    <FormControlLabel
                        control={
                            <Switch checked={includeCompleted} onChange={onIncludeCompletedChange} color="primary" />
                        }
                        label={<LocalizeText tag="includeCompletedTasks" />}
                    />
                    <Tooltip title={<LocalizeText tag="tasksLoading" />} placement="top-start" sx={{ float: "right" }}>
                        <IconButton color="primary" size="small">
                            <PriorityHighRounded />
                        </IconButton>
                    </Tooltip>
                </StyledSwitchContainer>

                <TableComponent
                    headCells={headCells}
                    isLoading={tasksPagination.query.isFetching}
                    rows={mapRows()}
                    skeletonVariant="regular"
                    collapsible
                    pagination={tasksPagination}
                />
            </Box>
        </div>
    );
};

export default TasksView;

import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { LocalizeText } from "components/localizer";
import { Breadcrumbs, CircularProgress, Grid, Typography } from "@mui/material";
import SubscriptionsContext from "contexts/subscriptions";
import { ISubscription } from "reducers/subscriptions";
import { SubscriptionInfoColumn } from "components/drawers/subscription/subscriptionInfoColumn";
import { getSubscriptionType } from "utils/helpers";
import { PageWithTabs } from "components/pageWithTabs";
import { usePaginationStore } from "utils/usePaginationStore";
import { MemberInfoColumn } from "components/drawers/members/memberInfoColumn";

export const SubscriptionView: FC = () => {
    const { fetchSubscription } = useContext(SubscriptionsContext);
    const [subscription, setSubscription] = useState<ISubscription | null>(null);
    const { subscriptionUuid } = useParams<{ subscriptionUuid: string }>();

    const associationPagination = usePaginationStore("member-subscription-associations", undefined, {
        subscription_uuid: subscriptionUuid,
    });

    useEffect(() => {
        (async () => {
            setSubscription(await fetchSubscription(subscriptionUuid));
        })();
        associationPagination.setParam("subscription_uuid", subscriptionUuid);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionUuid]);

    if (!subscription) {
        return (
            <div className="contentWrapper">
                <CircularProgress size="50px" />
            </div>
        );
    }

    return (
        <PageWithTabs
            overTitleContent={
                <Breadcrumbs sx={{ marginBottom: "20px" }}>
                    <Link to="/subscriptions">
                        <Typography>
                            <LocalizeText tag="memberships" />
                        </Typography>
                    </Link>
                    <Link to={`/subscriptions/${subscription.membership_id}`}>
                        <Typography>
                            <LocalizeText tag="subscriptions" />
                        </Typography>
                    </Link>
                    <Link to={`/subscriptions/${subscription.membership_id}/${subscription.uuid}/details`}>
                        <Typography>
                            <LocalizeText tag="subscriptionInformation" />
                        </Typography>
                    </Link>
                </Breadcrumbs>
            }
            titleTag="subscriptionInformation"
            overTabsContent={
                <Grid item>
                    {subscription.end_date && (
                        <Typography variant="body2" marginBottom="25px">
                            <LocalizeText
                                tag="subscriptionEndedInfo"
                                replace={{
                                    end_date: subscription.end_date,
                                }}
                            />
                        </Typography>
                    )}
                    <Typography variant="body2" marginBottom="20px">
                        {subscription.identifier} ({getSubscriptionType(subscription)})
                    </Typography>
                </Grid>
            }
            tabs={[
                {
                    tag: "details",
                    handle: "details",
                    content: (
                        <SubscriptionInfoColumn
                            subscription={subscription}
                            setSubscription={(data) => setSubscription(data)}
                        />
                    ),
                },
                {
                    tag: "members",
                    handle: "members",
                    content: (
                        <MemberInfoColumn
                            subscription={subscription}
                            setSubscription={(data) => setSubscription(data)}
                        />
                    ),
                    requiredPerm: "view_membersubscriptionassociation",
                },
            ]}
        />
    );
};

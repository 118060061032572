import React from "react";
import { Dialog, DialogContent, Grid, Link, Typography } from "@mui/material";
import { LocalizeText } from "components/localizer";
import SmallBanner from "components/smallBanner";
import { ProfileContext } from "contexts/profile";
import { useHistory } from "react-router-dom";
import If from "components/__helpers__/if";
import NewsSidebar from "components/newsComponents/NewsSidebar";
import { usePermission } from "utils/hooks/usePermission";
import colors from "styles/colors";
import { permission } from "utils/permissions";
import { TLanguageTag } from "contexts/language";

type AvailableDialogs = "Tenant" | "ArenaMember";

const StartpageView: React.FC = () => {
    const { user, landingPage } = React.useContext(ProfileContext);
    const { hasPermissions } = usePermission();
    const history = useHistory();
    const [selectedDialog, setDialog] = React.useState<AvailableDialogs | null>(null);
    const handleClose = (): void => {
        setDialog(null);
    };

    const changeLandingPage = (): void => {
        window.localStorage.setItem("landingPage", landingPage === "community" ? "default" : "community");
        window.location.reload();
    };

    const renderDialog = (): JSX.Element => {
        return (
            <Dialog open={!!selectedDialog} onClose={handleClose} maxWidth="md">
                <If truthy={selectedDialog}>
                    <DialogContent sx={{ padding: "3rem" }}>
                        <Typography variant="h4">
                            <LocalizeText tag={`dialogTitle${selectedDialog}` as TLanguageTag} />
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            <LocalizeText tag={`teaser${selectedDialog}` as TLanguageTag} />
                        </Typography>
                        <br />
                        <If truthy={selectedDialog === "Tenant"}>
                            <Link
                                variant="body1"
                                href="https://vasakronan.se/lediga-lokaler/"
                                target="_blank"
                                underline="hover"
                            >
                                <LocalizeText tag="availablePremises" />
                            </Link>
                        </If>
                        <If truthy={selectedDialog === "ArenaMember"}>
                            <Link
                                variant="body1"
                                href="https://vasakronan.se/arena-coworking/"
                                target="_blank"
                                underline="hover"
                            >
                                <LocalizeText tag="arenaCoworking" />
                            </Link>
                        </If>
                    </DialogContent>
                </If>
            </Dialog>
        );
    };

    const shouldShowArenaMemberInformation = hasPermissions(permission.ARENA_DASHBOARD);
    const shouldShowTenantInformation = hasPermissions(permission.TENANT_DASHBOARD);

    return (
        <Grid
            data-testid="StartpageView-root"
            container
            item
            md={12}
            wrap="nowrap"
            sx={{
                minHeight: "100%",
                backgroundColor: colors.white,
            }}
        >
            <Grid item lg={9} md={9} sx={{ padding: "40px 60px" }}>
                <Grid container item lg={12} justifyContent="space-evenly">
                    <Grid item lg={10} md={12}>
                        <Typography variant="h3" gutterBottom style={{ fontFamily: "Vasakronan" }}>
                            <LocalizeText
                                tag="dashboardTitle"
                                replace={user && { firstName: user.first_name, lastName: user.last_name }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item lg={10} md={12}>
                        <Typography variant="body1" style={{ fontFamily: "Apercu" }}>
                            <LocalizeText tag="dashboardBreadcrumbs" />
                            <Link href="https://www.vasakronan.se/gdpr" target="_blank" rel="noopener noreferrer">
                                www.vasakronan.se/gdpr
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item md={12} lg={12} justifyContent="space-evenly">
                    {landingPage === "community" ? (
                        <>
                            <Grid
                                item
                                lg={5}
                                md={12}
                                sx={{ paddingTop: "2rem" }}
                                onClick={() => history.push("staff-dashboard")}
                            >
                                <SmallBanner
                                    title="communityStaff"
                                    image={`${process.env.PUBLIC_URL}/assets/images/1024x683/invoices-1024x683.jpg`}
                                />
                            </Grid>
                            <Grid item lg={5} md={12} sx={{ paddingTop: "2rem" }} onClick={changeLandingPage}>
                                <SmallBanner
                                    title="customerPortalAsRepresentative"
                                    image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                lg={5}
                                md={12}
                                sx={{ paddingTop: "2rem" }}
                                onClick={() => {
                                    !shouldShowArenaMemberInformation
                                        ? setDialog("ArenaMember")
                                        : history.push("arena-member-dashboard");
                                }}
                            >
                                <SmallBanner
                                    inactive={!shouldShowArenaMemberInformation}
                                    title="arenaMember"
                                    image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={5}
                                md={12}
                                sx={{ paddingTop: "2rem" }}
                                onClick={() => {
                                    !shouldShowTenantInformation
                                        ? setDialog("Tenant")
                                        : history.push("tenant-dashboard");
                                }}
                            >
                                <SmallBanner
                                    inactive={!shouldShowTenantInformation}
                                    title="tenantCategory"
                                    image={`${process.env.PUBLIC_URL}/assets/images/1024x683/tenant-1024x683.jpg`}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <NewsSidebar />

            {renderDialog()}
        </Grid>
    );
};

export default StartpageView;

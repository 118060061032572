import { ChevronLeft } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import VKButton from "components/vkButton";
import UploadFileform, { FileUploadFormData } from "components/invoiceFileUpload/uploadFileForm";
import { LocalizeText } from "components/localizer";
import TableComponent, { IRow } from "components/tableComponent";
import { LanguageContext } from "contexts/language";
import React, { FC, useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { IInvoiceEvent, IInvoiceEventCreationError, IInvoiceEventCreationErrors } from "reducers/invoices";
import colors from "styles/colors";
import { getMonth } from "utils/helpers";
import { THeadCell } from "utils/types";

const InvoiceFileUploadView: FC = () => {
    const { currentLanguage } = useContext(LanguageContext);
    const [uploadData, setUploadData] = useState<FileUploadFormData>({
        community: undefined,
        period: new Date(),
        files: [],
    });
    const [errors, setErrors] = useState<IInvoiceEventCreationErrors>({});
    const history = useHistory();

    const onUpload = useCallback(
        async (result: IInvoiceEvent | IInvoiceEventCreationErrors) => {
            if (result["file_errors"] || result["data_errors"]) {
                setErrors(result as IInvoiceEventCreationErrors);
            } else if (result["uuid"]) {
                history.push(`/staff-invoicing/events/${result["uuid"]}`);
            }
        },
        [history]
    );

    const onDownload = useCallback(() => {
        const data = errors.file_errors
            ? fileErrorsToString(errors.file_errors)
            : fileErrorsToString(errors.data_errors ?? []);
        const a = document.createElement("a");
        a.href = "data:application/octet-stream," + encodeURIComponent(data);
        a.download = "errors.txt";
        a.click();
        a.remove();
    }, [errors]);

    if (Object.keys(errors).length === 0) {
        return (
            <div className="contentWrapper">
                <Box margin="50px auto" width="100%" maxWidth="700px" bgcolor={colors.white} padding="20px">
                    <UploadFileform
                        uploadData={uploadData}
                        setUploadData={setUploadData}
                        onUpload={onUpload}
                        onCancel={() => {
                            history.push("/staff-invoicing/events");
                        }}
                    />
                </Box>
            </div>
        );
    }

    const periodStart = new Date(uploadData.period);

    return (
        <div className="contentWrapper">
            <Grid container>
                <Grid item>
                    <Typography variant="h3">
                        <LocalizeText tag="errorMessages" />
                    </Typography>
                </Grid>
                <Grid item ml="auto" gap="10px" sx={{ display: "flex" }}>
                    <VKButton
                        tag="back"
                        variant="outlined"
                        startIcon={<ChevronLeft />}
                        onClick={() => {
                            setErrors({});
                        }}
                    />
                    <VKButton tag="exportErrors" variant="outlined" onClick={onDownload} />
                </Grid>
            </Grid>
            <Grid container gap="40px" alignItems="flex-end">
                <Grid item>
                    <LocalizeText tag="arena" />: {uploadData.community?.title}
                </Grid>
                <Grid item>
                    <LocalizeText tag="period" />: {getMonth(periodStart.getMonth(), currentLanguage)}
                </Grid>
                <Grid item>
                    <LocalizeText tag="file" />: {uploadData.files[0].name}
                </Grid>
            </Grid>
            {errors.file_errors && <ErrorsTable errors={errors.file_errors} includeRowNumber={true} />}
            {errors.data_errors && (
                <>
                    <Typography fontWeight="bold">
                        <LocalizeText tag="errorsWithoutRow" />
                    </Typography>
                    <ErrorsTable errors={errors.data_errors} />
                </>
            )}
        </div>
    );
};

const headCells: THeadCell[] = [
    { id: "columnName", label: <LocalizeText tag="columnName" /> },
    { id: "errorMessage", label: <LocalizeText tag="errorMessage" /> },
];

const headCellsWithRowNumer: THeadCell[] = [
    { id: "row", label: <LocalizeText tag="fileRow" /> },
    { id: "columnName", label: <LocalizeText tag="columnName" /> },
    { id: "errorMessage", label: <LocalizeText tag="errorMessage" /> },
];

interface ErrorTableProps {
    errors: IInvoiceEventCreationError[];
    includeRowNumber?: boolean;
}
const ErrorsTable: FC<ErrorTableProps> = ({ errors, includeRowNumber = false }) => {
    const rows: IRow[] = [];
    errors.forEach((error, index) => {
        if (Object.keys(error).length === 0) {
            return;
        }

        const columns: string[] = [],
            messages: (string | Record<string, string>)[][] = [];
        Object.entries(error).forEach(([key, arr]) => {
            columns.push(key);
            messages.push(arr);
        });

        const data = [];
        if (includeRowNumber) {
            data.push(index + 2);
        }
        data.push(
            <p key={`columnName_${index}`} style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {columns.map((col, j) => (
                    <span key={`col_${j}`} style={{ display: "block", width: "100%" }}>
                        {col}
                    </span>
                ))}
            </p>
        );
        data.push(
            <p key={`messages_${index}`} style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {messages.map((messageArr, j) => (
                    <span key={`message_${j}`} style={{ display: "block", width: "100%" }}>
                        {messageArr.map((m) => {
                            if (typeof m === "string") {
                                return m;
                            }

                            return getObjectString(m);
                        })}
                    </span>
                ))}
            </p>
        );

        rows.push({
            data,
        } as IRow);
    });

    return (
        <TableComponent
            headCells={includeRowNumber ? headCellsWithRowNumer : headCells}
            rows={rows}
            skeletonVariant="options"
        />
    );
};

const getObjectString = (obj: Record<string, string>): string => {
    let str = "";
    Object.entries(obj).forEach(([key, value]) => {
        str += `${key}: ${value} `;
    });
    return str;
};

const fileErrorsToString = (errors: IInvoiceEventCreationError[], includeRowNumber = false): string => {
    let ret = "";
    errors.forEach((error, index) => {
        Object.entries(error).forEach(([key, arr]) => {
            const row = `${includeRowNumber ? `${index + 2} ` : ""}${key}, ${arr.map((m) =>
                typeof m === "string" ? `${m} ` : getObjectString(m)
            )}\n`;
            ret += row;
        });
    });
    return ret;
};

export default InvoiceFileUploadView;

import React, { SyntheticEvent } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { Grid, Link, Typography } from "@mui/material";
import VKButton from "components/vkButton";
import LoginWrapper from "components/loginWrapper";
import { LocalizeText } from "components/localizer";
import { LinkContainer } from "./resetPassword";
import { unsubscribeFromNewsMailList } from "adapters/newsAdapter";
import { NotificationContext } from "contexts/notification";

const UnsubscribeMailListView: React.FC = () => {
    const params = useParams<{ encoded_email: string; token: string }>();
    const { enqueNotification } = React.useContext(NotificationContext);

    const history = useHistory();

    const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
        event.preventDefault();
        try {
            const success = await unsubscribeFromNewsMailList(params);
            if (success) {
                enqueNotification("success_updateNewsViaMailPreferences");
                history.push("/login");
            }
        } catch (error) {
            enqueNotification("error_updateNewsViaMailPreferences");
        }
    };

    return (
        <LoginWrapper testid="setPassword-root" title="unsubscribeFromNewsByMailTitle">
            <Typography variant="body2" mt="1em">
                <LocalizeText tag="confirmUnsubscribeFromNews" />
            </Typography>
            <VKButton
                data-testid="resetPassword-submitButton"
                tag="confirm"
                fullWidth
                template="primary"
                sx={{ marginTop: "10px", fontSize: "1rem" }}
                onClick={handleSubmit}
            />
            <LinkContainer container>
                <Grid item>
                    <Link component={RouterLink} to="/login" underline="hover">
                        <LocalizeText tag="goToLoginPage" />
                    </Link>
                </Grid>
            </LinkContainer>
        </LoginWrapper>
    );
};

export default UnsubscribeMailListView;

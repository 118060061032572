import React, { FC, useContext, useState } from "react";
import { LocalizeText } from "components/localizer";
import ConfirmDialog from "components/confirmDialog";
import { ModalContext } from "contexts/modals";
import SubscriptionsContext from "contexts/subscriptions";
import { CancelSubscriptionDatePicker } from "./cancelSubscriptionDatePicker";
import { ISubscription } from "reducers/subscriptions";
import { useHistory } from "react-router-dom";
import { QueryClientContext } from "@tanstack/react-query";
interface IProps {
    subscription: ISubscription;
}

export const CancelSubscription: FC<IProps> = ({ subscription }) => {
    const { isCancelingSubscription, cancelSubscription } = useContext(SubscriptionsContext);
    const { closeDrawers } = useContext(ModalContext);
    const queryClient = useContext(QueryClientContext);
    const history = useHistory();

    const [removeDate, setRemoveDate] = useState<Date | null>(null);

    const onAcceptRemoveSubscription = async (): Promise<void> => {
        if (!removeDate) {
            return;
        }

        const success = await cancelSubscription(subscription.uuid, removeDate);
        if (success) {
            closeDrawers();
        }
        await queryClient?.invalidateQueries({ queryKey: ["subscriptions"] });
        history.push("/subscriptions");
    };

    return (
        <>
            <CancelSubscriptionDatePicker onSubmit={(date: Date) => setRemoveDate(date)} subscription={subscription} />

            <ConfirmDialog
                open={!!removeDate}
                title={<LocalizeText tag="editFinish" />}
                description={<LocalizeText tag="editFinishDescription" />}
                onReject={() => setRemoveDate(null)}
                onAccept={() => onAcceptRemoveSubscription()}
                isLoading={isCancelingSubscription}
            />
        </>
    );
};

import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { PageWithTabs } from "components/pageWithTabs";
import { MembershipInfoColumn } from "components/membershipInfoColumn";
import { IMembership } from "reducers/memberships";
import { MembershipsContext } from "contexts/memberships";
import { MembershipStats } from "components/membershipStats";
import { prettifyOrgNumber } from "utils/helpers";
import { MembershipAdjustmentsContent } from "components/membershipAdjustmentsContent";
import { MembershipInvoiceOrdersTable } from "components/tableComponents/membershipInvoiceOrdersTable";
import { usePermission } from "utils/hooks/usePermission";
import VKButton from "components/vkButton";
import { usePaginationStore } from "utils/usePaginationStore";
import ConfirmDialog from "components/confirmDialog";
import { LocalizeText } from "components/localizer";

export const MembershipView: FC = () => {
    const { fetchMembership, updateMembership, terminateMembership } = useContext(MembershipsContext);
    const [membership, setMembership] = useState<IMembership | null>(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const { hasPermission } = usePermission();
    const { membershipId } = useParams<{ membershipId: string }>();

    useEffect(() => {
        (async () => {
            setMembership(await fetchMembership(parseInt(membershipId)));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipId]);

    const onSubmitUpdate = useCallback(
        async (newData: Partial<IMembership>): Promise<void> => {
            setMembership(await updateMembership(parseInt(membershipId), newData));
        },
        [updateMembership, membershipId]
    );

    const onAcceptTerminate = useCallback(async () => {
        setOpenConfirmDialog(false);
        setMembership(await terminateMembership(parseInt(membershipId)));
    }, [terminateMembership, membershipId]);

    if (!membership) {
        return (
            <div className="contentWrapper">
                <CircularProgress size="50px" />
            </div>
        );
    }

    return (
        <>
            <PageWithTabs
                titleTag="membershipInformation"
                overTabsContent={
                    <Grid container flex="col" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body2" marginBottom="20px">
                                {membership.organization.name} {prettifyOrgNumber(membership.organization.org_nr)}
                            </Typography>
                        </Grid>
                        {hasPermission("manage_contract") &&
                        hasPermission("is_community_user") &&
                        membership.is_possible_to_terminate ? (
                                <Grid item>
                                    <VKButton
                                        tag="terminateMembershipAgreement"
                                        template="primary"
                                        disabled={!!membership.end_date}
                                        onClick={() => setOpenConfirmDialog(!openConfirmDialog)}
                                    />
                                </Grid>
                            ) : null}
                    </Grid>
                }
                tabs={[
                    {
                        tag: "details",
                        handle: "details",
                        content: <MembershipInfoColumn membership={membership} onSubmitUpdate={onSubmitUpdate} />,
                    },
                    {
                        tag: "invoiceOrders",
                        handle: "invoice-orders",
                        content: <InvoicesContent membershipId={membership.id} />,
                        requiredPerm: "view_invoice",
                    },
                    {
                        tag: "statistics",
                        handle: "stats",
                        content: <MembershipStats membership={membership} />,
                        requiredPerm: "view_accessystatistics",
                    },
                    {
                        tag: "adjustments",
                        handle: "adjustments",
                        content: (
                            <MembershipAdjustmentsContent
                                membershipId={membership.id}
                                disabled={!!membership.end_date}
                            />
                        ),
                        requiredPerm: "add_invoiceorder",
                    },
                ]}
            />

            <ConfirmDialog
                open={openConfirmDialog}
                title={<LocalizeText tag="confirmMembershipTermination" />}
                description={<LocalizeText tag="confirmMembershipTerminationDescription" />}
                onReject={() => setOpenConfirmDialog(false)}
                onAccept={() => onAcceptTerminate()}
            />
        </>
    );
};

interface IInvoicesContentProps {
    membershipId: number;
}
const InvoicesContent: FC<IInvoicesContentProps> = ({ membershipId }) => {
    const pagination = usePaginationStore("invoice-orders", undefined, {
        membership: membershipId.toString(),
        page_size: 10,
        ordering: "-created",
    });

    return <MembershipInvoiceOrdersTable pagination={pagination} />;
};

import React from "react";
import { useParams } from "react-router-dom";
import { IContactPerson } from "reducers/memberships";
import { MembershipsContext } from "contexts/memberships";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import AddMembershipContactPerson from "components/drawers/contactPersons/addMembershipContact";
import BaseAgreementDetailsView from "./agreementDetailView";
import EditMembershipContactPerson from "components/drawers/contactPersons/editMembershipContactPerson";
import { usePermission } from "utils/hooks/usePermission";
import ContactPersonTable from "components/tableComponents/contactPersonTable";
import InvitationsTable from "components/tableComponents/invitationsTable";

const MembershipUserDetailsView: React.FC = () => {
    const {
        isFetchingMembership,
        isFetchingInvitations,
        isFetchingContactPersons,
        contactPersons,
        pendingContactPersons,
        membership,
        fetchContactPersons,
        fetchContactPersonsInvitations,
        fetchMembership,
        editContactPerson,
        removeContactPerson,
        removeInvitation,
    } = React.useContext(MembershipsContext);
    const { openDrawer } = React.useContext(ModalContext);
    const { hasPermission } = usePermission();
    const routeParams = useParams<{ membershipId: string }>();

    React.useEffect(() => {
        if ((!membership || membership.id.toString() !== routeParams.membershipId) && !isFetchingMembership) {
            fetchMembership(parseInt(routeParams.membershipId));
        }
        pendingContactPersons.initialize(async (params: Record<string, unknown>) => {
            return await fetchContactPersonsInvitations(parseInt(routeParams.membershipId), params);
        });
        pendingContactPersons.getInitial();
        contactPersons.initialize(async (params: Record<string, unknown>) => {
            return await fetchContactPersons(parseInt(routeParams.membershipId), params);
        });
        contactPersons.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOpenEdit = (person: IContactPerson): void => {
        if (membership) {
            openDrawer(
                EditMembershipContactPerson,
                {
                    person: person,
                    onSubmit: editContactPerson,
                    membership: membership,
                    isLoading: false,
                },
                "editRepresentative"
            );
        }
    };

    const handleRemove = async (contact: IContactPerson): Promise<void> => {
        if (contact.uuid) {
            await removeContactPerson(parseInt(routeParams.membershipId), contact.uuid);
        }
    };

    const getActionButton = (): React.ReactNode => {
        if (hasPermission("manage_contract") && hasPermission("add_membership") && membership) {
            return (
                <VKButton
                    template="primary"
                    tag="addNewRepresentative"
                    onClick={() => openDrawer(AddMembershipContactPerson, { membership }, "addNewRepresentative")}
                />
            );
        }
        return null;
    };

    return (
        <BaseAgreementDetailsView
            loadingText={isFetchingMembership}
            title={membership?.organization?.name}
            subtitle={membership?.agreement_nr}
            pagination={contactPersons}
            actionButton={getActionButton()}
        >
            <ContactPersonTable
                pagination={contactPersons}
                handleRemove={handleRemove}
                openEditDrawer={onOpenEdit}
                loading={isFetchingContactPersons || isFetchingMembership}
                permissions={{ delete: "delete_membership", patch: "change_membership" }}
            />
            {pendingContactPersons && (
                <InvitationsTable
                    handleRemove={async (invite) =>
                        await removeInvitation(parseInt(routeParams.membershipId), invite.uuid)
                    }
                    pagination={pendingContactPersons}
                    loading={isFetchingInvitations || isFetchingMembership}
                    permissions={{ delete: "delete_contactpersoninvitation" }}
                />
            )}
        </BaseAgreementDetailsView>
    );
};

export default MembershipUserDetailsView;

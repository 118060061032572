import React, { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from "react";
import { LocalizeText } from "components/localizer";
import TableComponent, { IRow } from "components/tableComponent";
import { dateIsPastOrToday, prettifyOrgNumber } from "utils/helpers";
import Dropdown from "components/dropdown";
import { format, parseISO } from "date-fns";
import { getExpireDate } from "utils/ecommerseTypes";
import { dateFormats } from "utils/formats";
import { MembershipsContext } from "contexts/memberships";
import { THeadCell } from "utils/types";
import { useHistory } from "react-router-dom";
import StyledSwitchContainer from "components/tableComponents/switchContainer";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { QueryClientContext } from "@tanstack/react-query";

const membershipStaffHeadCells: THeadCell[] = [
    { id: "name", sortId: "organization__name", label: <LocalizeText tag="commercialCompanyName" /> },
    { id: "org_nr", sortId: "organization__org_nr", label: <LocalizeText tag="orgNumber" /> },
    { id: "community_title", sortId: "default_community__title", label: <LocalizeText tag="arena" /> },
    { id: "agreement_nr", sortId: "agreement_nr", label: <LocalizeText tag="customerNumberShort" /> },
    { id: "start_date", sortId: "start_date", label: <LocalizeText tag="from" /> },
    { id: "end_date", sortId: "end_date", label: <LocalizeText tag="contractDuration" /> },
    { id: "", label: "", alignCell: "right" },
];

interface IMembershipTableProps {
    pagination: IUsePaginationStore<"memberships">;
}

export const MembershipStaffTable: FC<IMembershipTableProps> = ({ pagination }) => {
    const { isFetchingMemberships } = useContext(MembershipsContext);
    const history = useHistory();
    const queryClient = useContext(QueryClientContext);

    const [inclTerminatedFilter, setInclTerminatedFilter] = useState<boolean>(false);

    const viewAsRepresentative = useCallback(
        (org_nr: string): void => {
            window.localStorage.setItem("landingPage", "default");
            history.replace(`/dashboard?org=${org_nr}`);
            window.location.reload();
        },
        [history]
    );

    const onIncludeTerminatedMemberships = (event: ChangeEvent<HTMLInputElement>): void => {
        pagination.setParam("is_active", event.target.checked ? null : false);
        queryClient?.invalidateQueries({ queryKey: ["subscriptions"] });
        setInclTerminatedFilter(event.target.checked);
    };

    const rows = useMemo(
        (): IRow[] =>
            pagination.results.map((membership) => {
                const { organization, agreement_nr, start_date, end_date } = membership;
                const { name, org_nr } = organization;
                const getDateIsPastOrToday = end_date ? dateIsPastOrToday(parseISO(end_date ?? "")) : false;

                return {
                    onClick: () => history.push(`/staff-memberships/${membership.id}/details`),
                    disabled: getDateIsPastOrToday,
                    data: [
                        name || "-",
                        org_nr ? prettifyOrgNumber(org_nr) : "-",
                        membership.default_community?.title || "-",
                        agreement_nr || "-",
                        start_date ? format(parseISO(start_date ?? ""), dateFormats.WEBDATE) : "",
                        getExpireDate(end_date),
                        <Dropdown
                            inRow
                            key={agreement_nr}
                            items={[
                                {
                                    onClick: () => viewAsRepresentative(org_nr),
                                    label: <LocalizeText tag="showAsRepresentative" />,
                                },
                            ]}
                        />,
                    ],
                };
            }),
        [pagination.results, viewAsRepresentative, history]
    );

    return (
        <Box>
            <StyledSwitchContainer>
                <FormControlLabel
                    control={
                        <Switch
                            checked={inclTerminatedFilter}
                            onChange={onIncludeTerminatedMemberships}
                            color="primary"
                        />
                    }
                    label={<LocalizeText tag="includeTerminatedMemberships" />}
                />
            </StyledSwitchContainer>
            <TableComponent
                headCells={membershipStaffHeadCells}
                isLoading={isFetchingMemberships}
                rows={rows}
                skeletonVariant="options"
                pagination={pagination}
            />
        </Box>
    );
};

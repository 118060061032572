import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { LocalizeText } from "components/localizer";
import React, { ReactNode } from "react";
import If from "components/__helpers__/if";
import * as stackTraceParser from "stacktrace-parser";
import VKButton from "components/vkButton";
import { History } from "history";
import { showDevelopersFunctionality } from "utils/helpers";
import colors from "styles/colors";

interface IProps {
    history: History;
    children: ReactNode;
}

interface IStack {
    arguments?: string[];
    column: number;
    file: string;
    lineNumber: number;
    methodName: string;
}

interface IState {
    hasError: boolean;
    developerDialog: boolean;
    stacks?: IStack[];
    errorMessage?: string;
}

class ErrorBoundary extends React.Component<IProps> {
    public state: IState = {
        hasError: false,
        developerDialog: false,
    };

    static getDerivedStateFromError(error: Error): IState {
        if (error.stack) {
            const parsedStack = stackTraceParser.parse(error.stack) as IStack[];
            return {
                hasError: true,
                developerDialog: true,
                errorMessage: error.message,
                stacks: parsedStack.slice(0, 10),
            };
        }
        return { hasError: true, developerDialog: true, errorMessage: error.message };
    }

    handleCloseDeveloperDialog = (): void => {
        this.setState({ developerDialog: false });
    };

    redirect = (location: string): void => {
        this.setState({ hasError: false });
        this.props.history.push(location);
        window.location.reload();
    };

    render(): React.ReactNode {
        const { hasError, stacks, developerDialog, errorMessage } = this.state;
        if (hasError) {
            let stackAsText = errorMessage ? `${errorMessage}\n` : "\n";
            if (stacks) {
                stackAsText += `User Agent: ${window.navigator.userAgent}\n\n`;
                stacks.forEach((stack, i) => {
                    stackAsText += `[${i + 1}] at ${stack.file}: ${stack.lineNumber}  (${stack.methodName})\n`;
                });
            }
            return (
                <>
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            marginTop: "2rem",
                            backgroundColor: colors.white,
                            minHeight: "70%",
                        }}
                    >
                        <Grid item lg={10} md={12}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: "Vasakronan",
                                    textAlign: "center",
                                    marginBottom: "2rem",
                                    color: colors.vkBlue,
                                }}
                            >
                                <LocalizeText tag="generalErrorTitle" />
                            </Typography>
                            <Typography variant="body1">
                                <LocalizeText tag="generalError" />
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={12}>
                            <VKButton
                                onClick={() => this.redirect("/dashboard")}
                                type="submit"
                                template="primary"
                                tag="goToStartPage"
                            />
                        </Grid>
                    </Grid>
                    <If truthy={showDevelopersFunctionality()}>
                        <Dialog open={developerDialog} onClose={this.handleCloseDeveloperDialog} maxWidth="xl">
                            <DialogTitle id="alert-dialog-title">
                                {<LocalizeText tag="developerDialogTitle" />}
                            </DialogTitle>
                            <DialogContent>
                                <pre>
                                    <code>{stackAsText}</code>
                                </pre>
                            </DialogContent>
                            <DialogActions>
                                <VKButton
                                    onClick={() => {
                                        navigator.clipboard.writeText(stackAsText);
                                    }}
                                    color="primary"
                                >
                                    <LocalizeText tag="developerCopyError" />
                                </VKButton>
                            </DialogActions>
                        </Dialog>
                    </If>
                </>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;

import React, { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import Navigation from "components/navigation/navigation";
import { AuthContext } from "contexts/auth";
import { MembershipsContext } from "contexts/memberships";
import ErrorBoundary from "./__helpers__/ErrorBoundary";

const PrivateLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const history = useHistory();
    return (
        <AuthContext.Consumer>
            {(authContext) => (
                <MembershipsContext.Consumer>
                    {(membershipContext) => (
                        <Navigation
                            urlPath={history.location.pathname.substring(1)}
                            auth={authContext}
                            member={membershipContext.membership}
                            clearMembershipStorage={membershipContext.clearStorage}
                        >
                            <ErrorBoundary history={history}>{children}</ErrorBoundary>
                        </Navigation>
                    )}
                </MembershipsContext.Consumer>
            )}
        </AuthContext.Consumer>
    );
};

type IProps = Omit<RouteProps, "component"> & {
    component: React.ReactNode;
};

const PrivateRoute: React.FC<IProps> = ({ component, ...routeProps }) => {
    const { isLoggedIn } = React.useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (!isLoggedIn) {
            history.push("/login");
        }
    }, [isLoggedIn, history]);

    if (!isLoggedIn) {
        return null;
    }

    return (
        <Route {...routeProps}>
            <PrivateLayout>{component}</PrivateLayout>
        </Route>
    );
};
export default PrivateRoute;

import React, { FC, useContext, useEffect, useState } from "react";
import { TMonthKey } from "./statsUtils";
import { Grid, Typography } from "@mui/material";
import { MembershipsContext } from "contexts/memberships";
import { IMembership, TMembershipId } from "reducers/memberships";
import { useHistory } from "react-router-dom";
import { getMonth } from "utils/helpers";
import { LanguageContext } from "contexts/language";
import { LocalizeText } from "components/localizer";
import colors from "styles/colors";

interface IProps {
    overusage: Record<TMonthKey, TMembershipId[]>;
    monthKeys: TMonthKey[];
}
export const OverusageList: FC<IProps> = ({ overusage, monthKeys }) => {
    const { fetchMembership } = useContext(MembershipsContext);
    const { currentLanguage } = useContext(LanguageContext);
    const [memberships, setMembeships] = useState<Record<string, IMembership>>({});

    useEffect(() => {
        (async () => {
            const newIds: number[] = [];
            Object.values(overusage).forEach(async (ids) => {
                ids.forEach((id) => {
                    if (id.toString() in memberships || newIds.includes(id)) {
                        return;
                    }
                    newIds.push(id);
                });
            });
            const newMemberships: Record<string, IMembership> = {};
            await Promise.all(
                newIds.map(async (id) => {
                    const stringId = id.toString();
                    const membership = await fetchMembership(id);
                    newMemberships[stringId] = membership;
                })
            );

            setMembeships((fetched) => {
                return { ...fetched, ...newMemberships };
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overusage]);

    if (Object.keys(memberships).length === 0) {
        return null;
    }

    return (
        <Grid container direction="column" padding="0 20px 20px 20px">
            <Typography>
                <LocalizeText tag="overusage" />
            </Typography>
            {monthKeys.map((monthKey) => {
                const membershipIds = overusage[monthKey];
                if (!membershipIds || membershipIds.length === 0) {
                    return null;
                }

                return (
                    <Grid key={`overusage_${monthKey}`} item>
                        <Grid container alignItems="center" gap="20px">
                            <Typography>
                                {getMonth(parseInt(monthKey.substring(5, 7)) - 1, currentLanguage)}:{" "}
                            </Typography>
                            {membershipIds.map((id) => {
                                if (!memberships[id]) {
                                    return null;
                                }

                                return (
                                    <Grid key={`membership_overusage_${id}`} item width="fit-content">
                                        <MembershipLink monthKey={monthKey} membership={memberships[id]} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

interface IMembershipLinkProps {
    monthKey: TMonthKey;
    membership: IMembership;
}
const MembershipLink: FC<IMembershipLinkProps> = ({ monthKey, membership }) => {
    const history = useHistory();

    return (
        <Typography
            sx={{
                color: colors.vkBlue,
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "underline",
                },
            }}
            onClick={() => history.push(`/staff-memberships/${membership.id}/stats/${monthKey.substring(0, 7)}`)}
        >
            {membership.organization.name}
        </Typography>
    );
};

import React from "react";
import Banner from "components/banner";
import { Divider, Grid, List, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IPaginationStore } from "utils/paginationStore";
import SearchField from "components/tableComponents/paginationSearchField";
import VKButton from "components/vkButton";

interface IBaseAgreementDetailsProps {
    loadingText: boolean;
    title: React.ReactNode;
    subtitle: React.ReactNode;
    pagination?: IPaginationStore<unknown>;
    actionButton: React.ReactNode;
    children?: React.ReactNode;
}

const BaseAgreementDetailsView: React.FC<IBaseAgreementDetailsProps> = ({
    loadingText,
    title,
    subtitle,
    pagination,
    actionButton,
    children,
}) => {
    const history = useHistory();

    return (
        <div data-testid="agreementDetail-root" className="contentWrapper">
            <Banner
                title="representative"
                subTitle="bannerRepresentatives"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/contactPersons-1024x683.jpg`}
                imageStyle={{ objectPosition: "50% 0%" }}
            />
            <Grid container>
                <Grid item xs={6}>
                    <VKButton
                        variant="outlined"
                        size="small"
                        leftAddon={<ArrowBackIcon />}
                        tag="back"
                        onClick={() => history.goBack()}
                    />
                </Grid>
                <List component={Grid} item xs={7} dense>
                    <ListItem key="city">
                        <ListItemText disableTypography>
                            <Typography data-testid="agreement_title" variant="h4" style={{ fontFamily: "Vasakronan" }}>
                                {loadingText ? <Skeleton variant="rectangular" /> : title}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem key="name">
                        <ListItemText disableTypography>
                            <Typography variant="h6">
                                {loadingText ? <Skeleton variant="rectangular" /> : subtitle}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <Divider absolute key="divider" />
                </List>
                <Grid item xs={6} style={{ marginTop: "60px" }}>
                    {pagination && <SearchField pagination={pagination} tag="searchRepresentative" />}
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end" style={{ marginTop: "60px" }}>
                    {loadingText ? (
                        <Skeleton variant="rectangular">
                            <VKButton template="primary" size="medium" />
                        </Skeleton>
                    ) : (
                        actionButton
                    )}
                </Grid>
            </Grid>
            {children}
        </div>
    );
};

export default BaseAgreementDetailsView;

import React from "react";
import MenuIcon from "@mui/icons-material/MoreVert";
import { TMouseEvent } from "utils/types";
import { LocalizeText } from "components/localizer";
import { LanguageContext } from "contexts/language";
import { useHistory } from "react-router-dom";
import colors from "styles/colors";
import {
    Box,
    Button,
    ClickAwayListener,
    Grow,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Skeleton,
    styled
} from "@mui/material";
import { ProfileContext } from "contexts/profile";

const ProfileNameContainer = styled("div")(() => ({
    fontSize: "1rem",
    marginLeft: "1rem",
    width: "100%",
    textAlign: "left",
    color: colors.black
}));

interface IProps {
    logout: () => void;
}
const UserMenu: React.FC<IProps> = ({ logout }) => {
    const { currentLanguage, changeLanguage } = React.useContext(LanguageContext);
    const { isFetchingProfile, user, landingPage } = React.useContext(ProfileContext);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [isOpen, setOpen] = React.useState(false);

    const history = useHistory();

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent | null): void => {
        if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleSwitchLanguage = (event: TMouseEvent): void => {
        if (currentLanguage === "sv") {
            changeLanguage("en");
        } else {
            changeLanguage("sv");
        }
        handleClose(null);
    };

    const handleListKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };

    const handleSwitchLandingPage = (event: TMouseEvent): void => {
        window.localStorage.setItem("landingPage", landingPage === "community" ? "default" : "community");
        history.replace("/dashboard");
        window.location.reload();
    };
    

    return (
        <Box 
            id="sidebar-button" 
            sx={{
                backgroundColor: "white",
                marginBottom: "10px",
            }}>
            <Button
                data-testid="dropDown-listButton"
                ref={anchorRef}
                onClick={handleToggle}
                sx={{
                    padding: 0,
                    height: "3rem",
                    backgroundColor: colors.white,
                    width: "100%",
                    textTransform: "none",
                }}
            >
                <ProfileNameContainer>
                    <ListItemText
                        data-testid="dropDown-userInfo"
                        primary={
                            !isFetchingProfile ? (
                                <>{user && `${user.first_name} ${user.last_name}`}</>
                            ) : (
                                <Skeleton
                                    data-testid="dropDown-userSkeleton"
                                    variant="rectangular"
                                    sx={{width: "80%"}}
                                />
                            )
                        }
                    />
                </ProfileNameContainer>
                <MenuIcon color="primary" />
            </Button>
            <Popper
                sx={{
                    zIndex: 9000,
                    width: "100%",
                }}
                open={isOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={"bottom"}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    sx={{ backgroundColor: colors.white }}
                                    autoFocusItem={isOpen}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem
                                        divider
                                        data-testid="dropDown-item-profile"
                                        onClick={() => {
                                            handleClose(null);
                                            history.push("/profile");
                                        }}
                                    >
                                        <LocalizeText tag="profile" />
                                    </MenuItem>
                                    <MenuItem
                                        divider
                                        data-testid="dropDown-item-langSelector"
                                        onClick={handleSwitchLanguage}
                                    >
                                        <LocalizeText tag="langSelector" />
                                    </MenuItem>
                                    {user?.has_community_landing_page && (
                                        <MenuItem
                                            divider
                                            data-testid="dropDown-item-changeLandingPage"
                                            onClick={handleSwitchLandingPage}
                                        >
                                            <LocalizeText tag={landingPage === "community" ? "showAsRepresentative" : "showAsCommunityStaff"} />
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        divider
                                        data-testid="dropDown-item-privacyPolicy"
                                        onClick={() => {
                                            handleClose(null);
                                            window.open(
                                                "https://vasakronan.se/om-vasakronan/foretaget/gdpr/",
                                                "__blank"
                                            );
                                        }}
                                    >
                                        <LocalizeText tag="privacyPolicy" />
                                    </MenuItem>
                                    <MenuItem
                                        data-testid="dropDown-item-logout"
                                        onClick={() => logout()}
                                    >
                                        <LocalizeText tag="logout" />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default UserMenu;

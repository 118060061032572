import React from "react";
import { useHistory } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { fontType, LocalizeText } from "components/localizer";
import { ProfileContext } from "contexts/profile";
import Skeleton from "@mui/material/Skeleton";
import { MembershipsContext } from "contexts/memberships";
import { useFeatureToggle } from "utils/hooks/useFeatureToggle";
import { Badge, styled } from "@mui/material";
import colors from "styles/colors";
import { RequiredPerm, usePermission } from "utils/hooks/usePermission";
import { permission } from "utils/permissions";
import { useProfileNotifications } from "utils/hooks/useProfileNotifications";
import { TLanguageTag } from "contexts/language";

const SelectionMarker = styled("div")(() => ({
    position: "absolute",
    left: 0,
    top: 0,
    width: 3,
    height: "100%",
    backgroundColor: "white",
}));

interface IPanelItem {
    path: string;
    tagName: TLanguageTag;
    selectedUrl: string;
    requriedPerms: RequiredPerm;
    padded?: boolean;
    idPath?: number;
    inactive?: boolean;
}
const PanelItem: React.FC<IPanelItem> = ({ path, tagName, selectedUrl, padded, idPath, inactive, requriedPerms }) => {
    const history = useHistory();
    const { hasPermissions } = usePermission();
    const { getRouteNotificationCount } = useProfileNotifications();

    const selected = selectedUrl === path;
    const fullPath = (): string => {
        if (idPath && idPath >= 0) {
            return `/${path}/${idPath}`;
        }
        return `/${path}`;
    };
    if (!hasPermissions(requriedPerms)) {
        return null;
    }

    return (
        <ListItem
            data-testid={`items-expansion-${path}-choice`}
            button
            disabled={inactive}
            sx={{
                marginLeft: padded ? "20px" : 0,
                width: padded ? "calc(100% - 20px)" : "100%",
                paddingTop: "5px",
                paddingBottom: "5px",
                "& .MuiListItemText-primary": {
                    color: colors.white,
                },
            }}
            onClick={() => history.push(fullPath())}
        >
            {selected && <SelectionMarker />}
            <ListItemText
                primary={
                    <Badge
                        color="primary"
                        max={9}
                        badgeContent={getRouteNotificationCount(path)}
                        sx={{ paddingRight: "8px" }}
                    >
                        <LocalizeText type={selected ? fontType.BOLD : fontType.NORMAL} tag={tagName} />
                    </Badge>
                }
            />
        </ListItem>
    );
};

interface IProps {
    urlPath: string;
}
const Items: React.FC<IProps> = ({ urlPath }) => {
    const { isFetchingProfile, isFetchingOrgs, isFetchingOrgPermissions, landingPage } =
        React.useContext(ProfileContext);
    const { orgHasSingleMembership, membership } = React.useContext(MembershipsContext);
    const selected = urlPath.split("/")[0];
    const [membershipId, setMembershipIdPath] = React.useState(-1);
    const isActive = useFeatureToggle();

    React.useEffect(() => {
        if (typeof membership !== "undefined") {
            setMembershipIdPath(orgHasSingleMembership ? membership.id : -1);
        } else {
            setMembershipIdPath(-1);
        }
    }, [membership, orgHasSingleMembership]);

    if (isFetchingProfile || isFetchingOrgs || isFetchingOrgPermissions) {
        return (
            <>
                {Array.from(new Array(7)).map((_, index) => {
                    return (
                        <ListItem button key={index}>
                            <ListItemText primary={<Skeleton variant="rectangular"></Skeleton>} />
                        </ListItem>
                    );
                })}
            </>
        );
    }

    if (landingPage === "community") {
        return (
            <>
                <PanelItem
                    tagName="dashboardCategory"
                    path="dashboard"
                    selectedUrl={selected}
                    requriedPerms={permission.NONE}
                />
                <PanelItem
                    tagName="arena"
                    path="staff-dashboard"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_DASHBOARD}
                />
                {isActive("statistics_page") ? (
                    <PanelItem
                        padded
                        path="statistics"
                        tagName="statistics"
                        selectedUrl={selected}
                        requriedPerms={permission.ARENA_STAFF_STATISTICS}
                    />
                ) : null}
                <PanelItem
                    padded
                    path="staff-memberships"
                    tagName="memberships"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_MEMBERSHIPS}
                />
                <PanelItem
                    padded
                    path="staff-external-memberships"
                    tagName="externalMemberships"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_EXTERNAL_MEMBERSHIPS}
                />
                <PanelItem
                    padded
                    path="staff-sergel"
                    tagName="sergel"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_SERGEL_MEMBERSHIPS}
                />
                <PanelItem
                    padded
                    path="staff-products"
                    tagName="products"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_PRODUCTS}
                />
                <PanelItem
                    padded
                    path="staff-cost-calculations"
                    tagName="bannerCostCalculationsTitle"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_COSTS}
                />
                <PanelItem
                    padded
                    path="staff-invoicing"
                    tagName="invoicing"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_INVOICES}
                />
                <PanelItem
                    padded
                    path="staff-representatives"
                    tagName="contactPersons"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_REPRESENTATIVES}
                />
                <PanelItem path="events" tagName="events" selectedUrl={selected} requriedPerms={permission.EVENTS} />
                <PanelItem
                    path="staff-management"
                    tagName="communityStaff"
                    selectedUrl={selected}
                    requriedPerms={permission.ARENA_STAFF_MANAGEMENT}
                />
                <PanelItem
                    path="contact-us"
                    tagName="contactUs"
                    selectedUrl={selected}
                    requriedPerms={permission.NONE}
                />
            </>
        );
    }
    return (
        <>
            <PanelItem
                tagName="dashboardCategory"
                path="dashboard"
                selectedUrl={selected}
                requriedPerms={permission.NONE}
            />
            <PanelItem tagName="news" path="news" selectedUrl={selected} requriedPerms={permission.NEWS} />
            <PanelItem
                path="arena-member-dashboard"
                tagName="arenaMember"
                selectedUrl={selected}
                requriedPerms={permission.ARENA_DASHBOARD}
            />
            <PanelItem
                padded
                path="memberships"
                tagName="memberships"
                selectedUrl={selected}
                requriedPerms={permission.MEMBERSHIPS}
            />
            <PanelItem
                padded
                path="subscriptions"
                tagName="subscriptionCategory"
                selectedUrl={selected}
                idPath={membershipId}
                requriedPerms={permission.SUBSCRIPTIONS}
            />
            <PanelItem
                padded
                path="members"
                tagName="members"
                selectedUrl={selected}
                requriedPerms={permission.MEMBERS}
            />
            <PanelItem
                padded
                path="arena-offices"
                tagName="arenaOffices"
                selectedUrl={selected}
                idPath={membershipId}
                requriedPerms={permission.ARENA_OFFICE}
            />
            <PanelItem
                padded
                path="arena-buildings"
                tagName="arenaInformation"
                selectedUrl={selected}
                requriedPerms={permission.ARENA_BUILDINGINFO}
            />
            {/* Tenant */}
            <PanelItem
                path="tenant-dashboard"
                tagName="tenantCategory"
                selectedUrl={selected}
                requriedPerms={permission.TENANT_DASHBOARD}
            />
            <PanelItem padded path="tasks" tagName="tasks" selectedUrl={selected} requriedPerms={permission.TASKS} />
            <PanelItem
                padded
                path="leases"
                tagName="leasesCategory"
                selectedUrl={selected}
                requriedPerms={permission.LEASES}
            />
            <PanelItem
                padded
                path="realestates"
                tagName="realestateInformation"
                selectedUrl={selected}
                requriedPerms={permission.BUILDING_INFO}
            />
            <PanelItem path="services" tagName="services" selectedUrl={selected} requriedPerms={permission.SERVICES} />
            <PanelItem
                path="representatives"
                tagName="representative"
                selectedUrl={selected}
                requriedPerms={permission.REPRESENTATIVES}
            />
            <PanelItem path="contact-us" tagName="contactUs" selectedUrl={selected} requriedPerms={permission.NONE} />
        </>
    );
};

export default Items;

import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import HorizontalCard, { HorizontalCardSkeleton } from "components/horizontalCard";
import { Button, Grid, Typography } from "@mui/material";
import { IBuilding } from "reducers/realestates";
import { LocalizeText } from "components/localizer";
import ContactPerson from "components/contactPerson";
import BuildingCardImage from "./buildingCardImage";
import { getContactPerson } from "utils/buildingHelpers";
import DimmedTypoGraphy from "components/dimmedTypography";

interface IProps extends RouteComponentProps {
    loading: boolean;
    buildings: IBuilding[];
    navigateToDetails: (id: string) => void;
    isArenaBuildings?: boolean;
}
const BuildingList: React.FC<IProps> = ({ buildings, navigateToDetails, loading, isArenaBuildings=false }) => {

    const generateSkeletonCardsList = (): JSX.Element[] => {
        return (
            Array.from(new Array(3)).map((_, index) => {
                return (
                    <Grid item xs={12} key={index} data-testid={`skeleton-card-${index}`}>
                        <HorizontalCardSkeleton elevation={0} />
                    </Grid>
                );
            })
        );
    };

    const formatBuildingName = (building: IBuilding): string => {
        let buildingName: string | undefined = building.popular_name_tenant;
        if (building.is_arena && isArenaBuildings) {
            buildingName = building.arena_name;
        }
        return buildingName || " - ";
    };

    if (loading) {
        return (
            <Grid container spacing={4} mt={"1rem"}>
                {generateSkeletonCardsList()}
            </Grid>
        );
    }
    
    if (!loading && (!buildings || buildings.length === 0)) {
        return (
            <Typography variant="h6"><LocalizeText tag="noDataAvailable" /></Typography>
        );
    }

    return (
        <Grid container spacing={4} mt={"1rem"}>
            {buildings.map((building: IBuilding, index: number) => (
                <Grid item xs={12} key={index} data-testid={`building-card-${building.building_uuid}`}>
                    <HorizontalCard
                        imageBreakPoints={{
                            xs: 4,
                            lg: 3,
                            xl: 2
                        }}
                        contentBreakPoints={{
                            xs: 8,
                            lg: 9,
                            xl: 10
                        }}
                        elevation={0}
                        rounded={true}
                        image={<BuildingCardImage building={building} />}
                        actions={
                            <Button variant="text" color="primary" onClick={() => navigateToDetails(building.building_uuid)}>
                                <Typography variant="body2">
                                    <LocalizeText tag="clickToReadMore" />
                                </Typography>
                            </Button>
                        }
                        onCardClick={() => navigateToDetails(building.building_uuid)}>
                        <Typography variant="body1">{formatBuildingName(building)}</Typography>
                        <DimmedTypoGraphy variant="body2">{building.visiting_address}</DimmedTypoGraphy>
                        <DimmedTypoGraphy variant="body2" gutterBottom>{building.city}</DimmedTypoGraphy>
                        <ContactPerson responsible={getContactPerson(building, isArenaBuildings)} />
                    </HorizontalCard>
                </Grid>
            ))}
        </Grid >
    );
};

export default withRouter(BuildingList);

import React, { FC, useContext, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { FormControl, FormHelperText, Grid, Link, styled, Typography } from "@mui/material";
import VKButton from "components/vkButton";
import LoginWrapper from "components/loginWrapper";
import { LocalizeText } from "components/localizer";
import Password from "components/password";
import { AuthContext } from "contexts/auth";
import { TFormEvent } from "utils/types";
import { validatePasswordGetErrorTag } from "utils/helpers";
import PasswordCriteria from "components/passwordCriteria";
import colors from "styles/colors";
import { FormFields } from "./login";
import { TLanguageTag } from "contexts/language";
import { InfoDialog } from "components/confirmDialog";

export const LinkContainer = styled(Grid)(() => ({
    fontSize: "16px",
    marginTop: "20px",
    color: `${colors.black} !important`,

    "& a:visited": {
        color: `${colors.black} !important`,
    },
}));

interface IPassword {
    password: string;
    rePassword: string;
}

const ResetPasswordView: FC = () => {
    const { resetPassword, isResettingPassword } = useContext(AuthContext);
    const params = useParams<{ token: string }>();
    const [values, setValues] = useState<IPassword>({
        password: "",
        rePassword: "",
    });
    const [showError, setShowError] = useState<boolean>(false);
    const [showExpiredError, setShowExpiredError] = useState<boolean>(false);
    const history = useHistory();

    const handleSubmit = async (event: TFormEvent): Promise<void> => {
        event.preventDefault();
        const response_code = await resetPassword(values.password, params.token);
        if (response_code === 200) {
            history.push("/login");
        } else if (response_code === 412) {
            setShowExpiredError(true);
        } else {
            setShowError(true);
        }
    };

    const validate = (): string => {
        if (!params.token) {
            return "noToken";
        }
        return validatePasswordGetErrorTag(values.password, values.rePassword);
    };

    const setValue = (type: string, prop: string): void => {
        setShowError(false);
        setValues({ ...values, [type]: prop });
    };

    const errorTag = validate();
    const criteriaTags: TLanguageTag[] = ["passwordCriteria1", "passwordCriteria2", "passwordCriteria3"];

    return (
        <LoginWrapper testid="setPassword-root" inputLg={4} title="resetPasswordTitle">
            {!params.token}
            <PasswordCriteria criteriaTags={criteriaTags} />
            <FormFields onSubmit={handleSubmit}>
                <FormControl error>
                    <Password onChange={(value: string) => setValue("password", value)} />
                    <Password onChange={(value: string) => setValue("rePassword", value)} />
                    {errorTag && (values.password || values.rePassword) && (
                        <FormHelperText>
                            <LocalizeText tag={errorTag as TLanguageTag} />
                        </FormHelperText>
                    )}
                </FormControl>
                <InfoDialog
                    open={showExpiredError}
                    title={<LocalizeText tag="resetPasswordExpiredLinkTitle" />}
                    description={
                        <Grid>
                            <LocalizeText tag="resetPasswordExpiredLinkText" />
                            <Link
                                onClick={() => history.push("/login")}
                                underline="hover"
                                style={{ cursor: "pointer" }}
                            >
                                <LocalizeText tag="login" />
                            </Link>
                        </Grid>
                    }
                    onClose={() => setShowExpiredError(false)}
                />
                {showError ? (
                    <Typography variant="body2" color="error">
                        <LocalizeText tag="passwordChangeFail" />
                    </Typography>
                ) : null}
                <VKButton
                    data-testid="resetPassword-submitButton"
                    type="submit"
                    tag="continue"
                    fullWidth
                    disabled={!!errorTag}
                    isLoading={isResettingPassword}
                    template="primary"
                    sx={{ marginTop: "10px", fontSize: "1rem" }}
                />
            </FormFields>
            <LinkContainer container>
                <LocalizeText tag="passwordDeadline" />
                <Grid item marginTop={2}>
                    <Link component={RouterLink} to="/login" underline="hover">
                        <LocalizeText tag="goToLoginPage" />
                    </Link>
                </Grid>
            </LinkContainer>
        </LoginWrapper>
    );
};

export default ResetPasswordView;

import React, { useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import VKButton from "components/vkButton";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import LoginWrapper from "components/loginWrapper";
import { LocalizeText } from "components/localizer";
import Password from "components/password";
import { AuthContext } from "contexts/auth";
import { IAuthorization } from "reducers/auth";
import { TFormEvent } from "utils/types";
import { ProfileContext } from "contexts/profile";
import { Checkbox, FormControlLabel, Modal, Typography } from "@mui/material";
import Register2FAMethod from "components/2FA/register2FAMethod";
import { validatePasswordGetErrorTag } from "utils/helpers";
import PasswordCriteria from "components/passwordCriteria";
import { FormFields, TwoFABackgroundBox } from "./login";
import { LinkContainer } from "./resetPassword";
import { TLanguageTag } from "contexts/language";

interface IPassword {
    password: string;
    rePassword: string;
}

const SetPasswordView: React.FC = () => {
    const {
        setPassword,
        isSavingNewPassword,
        login,
        clearAuthState,
        isLoggedIn,
        isLoggingIn,
        isFetchingTotpDevices,
        isFetchingSmsDevices,
    } = React.useContext(AuthContext);
    const { fetchUserProfile } = React.useContext(ProfileContext);
    const params = useParams<{ token: string }>();
    const [registering2fa, setRegistering2fa] = React.useState<boolean>(false);
    const [authorization, setAuthorization] = React.useState<IAuthorization | null>(null);
    const [values, setValues] = React.useState<IPassword>({
        password: "",
        rePassword: "",
    });
    const [sendNewsByMail, setSendNewsByMail] = React.useState(true);
    const [showError, setShowError] = useState<boolean>(false);
    const history = useHistory();

    const handleSubmit = async (event: TFormEvent): Promise<void> => {
        event.preventDefault();
        const userData = await setPassword(values.password, values.rePassword, params.token, sendNewsByMail);
        const success = await login(userData["email"], values.password);
        if (success?.key) {
            setAuthorization(success);
            setRegistering2fa(true);
        } else if (userData) {
            // Redirect to login if user has been created.
            history.push("/login");
        }

        if (!success) {
            setShowError(true);
        }
    };

    const onClose = (): void => {
        if (isLoggedIn) {
            onFinish();
        } else {
            clearAuthState();
            setRegistering2fa(false);
            history.push("/login");
        }
    };

    const onFinish = (): void => {
        setRegistering2fa(false);
        history.push("/dashboard");
        fetchUserProfile();
    };

    const validate = (): string => {
        if (!params.token) {
            return "noToken";
        }
        return validatePasswordGetErrorTag(values.password, values.rePassword);
    };

    const setValue = (type: string, prop: string): void => {
        setValues({ ...values, [type]: prop });
    };

    const errorTag = validate();
    const criteriaTags: TLanguageTag[] = ["passwordCriteria1", "passwordCriteria2", "passwordCriteria3"];

    return (
        <LoginWrapper testid="setPassword-root" inputLg={4}>
            {!params.token}
            <PasswordCriteria criteriaTags={criteriaTags} />
            <FormFields onSubmit={handleSubmit}>
                <FormControl error>
                    <Password onChange={(value: string) => setValue("password", value)} />
                    <Password onChange={(value: string) => setValue("rePassword", value)} />
                    {errorTag && (values.password || values.rePassword) && (
                        <FormHelperText>
                            <LocalizeText tag={errorTag as TLanguageTag} />
                        </FormHelperText>
                    )}
                </FormControl>
                {showError ? (
                    <Typography variant="body2" color="error">
                        <LocalizeText tag="passwordChangeFail" />
                    </Typography>
                ) : null}
                <VKButton
                    data-testid="setPassword-submitButton"
                    type="submit"
                    tag="continue"
                    fullWidth
                    disabled={!!errorTag}
                    isLoading={isSavingNewPassword || isLoggingIn || isFetchingSmsDevices || isFetchingTotpDevices}
                    template="primary"
                    style={{ marginTop: "10px", fontSize: "1rem" }}
                />
            </FormFields>
            <LinkContainer container>
                <Grid item>
                    <Link component={RouterLink} to="/login" underline="hover">
                        <LocalizeText tag="alreadyHaveAccount" />
                    </Link>
                </Grid>
            </LinkContainer>
            {authorization && (
                <Modal
                    open={registering2fa}
                    onClose={onClose}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                    <TwoFABackgroundBox>
                        <Register2FAMethod onFinish={onFinish} authorizationObject={authorization} />
                    </TwoFABackgroundBox>
                </Modal>
            )}
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={sendNewsByMail}
                        onChange={(e) => setSendNewsByMail(e.target.checked)}
                        name="newsletter"
                    />
                }
                label={<LocalizeText tag="newsletterYes" />}
            />
        </LoginWrapper>
    );
};

export default SetPasswordView;

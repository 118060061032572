import React from "react";
import { Switch, HashRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Notification from "components/notifications";
import defaultTheme from "styles/mainTheme";
import PrivateRoute from "components/privateRoute";
import PublicRoute from "components/publicRoute";
import customGlobalStyles from "components/globalStyles";

// Contexts
import { AuthProvider } from "contexts/auth";
import { NotificationProvider } from "contexts/notification";
import { LanguageProvider } from "contexts/language";
import { SubscriptionsProvider } from "contexts/subscriptions";
import { ArenasProvider } from "contexts/arenas";
import { ProfileContext, ProfileProvider } from "contexts/profile";
import { MembershipsProvider } from "contexts/memberships";
import { RealestatesProvider } from "contexts/realestates";
import { NewsArticleProvider } from "contexts/newsArticles";
import { FeatureFlagProvider } from "contexts/featureFlag";
import { RoleManagementProvider } from "contexts/roleManagement";
import { ModalProvider } from "contexts/modals";
import { LeasesProvider } from "contexts/leases";
import { CommunityProvider } from "contexts/community";
import { ExternalMembershipsProvider } from "contexts/externalMemberships";
import { InvoicesProvider } from "contexts/invoices";
import { ProductsProvider } from "contexts/products";
import { MembersProvider } from "contexts/members";

// Views
import LoginView from "views/login";
import SetPasswordView from "views/setPassword";
import ArenaView from "views/membership/arenaView";
import MembershipsView from "views/membership/membershipsView";
import SubscriptionsView from "views/membership/subscriptionsView";
import ContactUsView from "views/contactUsView";
import BuildingsView from "views/lease/buildingsView";
import LeasesViewV2 from "views/lease/leaseViewV2";
import CustomerLedgers from "views/lease/customerLedgers";
import ProfileView from "views/profileView";
import StartpageView from "views/startpageView";
import NewsView from "views/newsView";
import TenantDashboardView from "views/lease/tenantDashboardView";
import LeaseSelectView from "views/lease/leaseSelectView";
import ArenaMemberDashboard from "views/membership/arenaMemberDashboardView";
import PageNotFound from "views/pageNotFound";
import BuildingInformation from "views/lease/buildingInformation";
import ArenaBuildings from "views/membership/arenaBuildings";
import ArenaInformation from "views/membership/arenaInformation";
import ResetPassword from "views/resetPassword";
import AxiosInterceptor from "components/__helpers__/axiosInterceptor";
import AcceptInvitationView from "views/acceptInvitation";
import TasksView from "views/lease/tasksView";
import ServicesView from "views/servicesView";
import RoleUserManagementView from "views/roleUserManagement/roleUserManagementView";
import LeaseUserDetailsView from "views/roleUserManagement/agreementDetailViews/leaseUserDetailsView";
import MembershipUserDetailsView from "views/roleUserManagement/agreementDetailViews/membershipUserDetailsView";
import UnsubscribeMailListView from "views/unsubscribeMailList";
import ArenaCommunityStaffDashboardView from "views/arena-staff/arenaCommunityStaffDashboardView";
import { MembershipsStaffView } from "views/arena-staff/membershipStaffView";
import CommunityStaffManagementView from "views/arena-staff/communityStaffManagementView";
import CostCalculationRunsView from "views/arena-staff/costCalcultaionRunView";
import StaffRepresentativesView from "views/arena-staff/staffRepresentativesView";
import ExternalMembershipsView from "views/arena-staff/externalMembershipsView";
import InvoicingView from "views/arena-staff/invoicingView";
import InvoiceEventOrdersView from "views/arena-staff/invoiceEventOrdersView";
import InvoiceView from "views/arena-staff/invoiceView";
import ProductsView from "views/arena-staff/productsView";
import InvoiceFileUploadView from "views/arena-staff/invoiceFileUploadView";
import SergelConferenceView from "views/arena-staff/sergelConferenceView";
import { MembersView } from "views/membership/membersView";
import { SubscriptionView } from "views/membership/subscriptionView";
import { StatisticsView } from "views/arena-staff/statisticsView";
import { MembershipView } from "views/membership/membershipView";
import EventsView from "components/events/eventsView";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const providerWrapping = (component: React.ReactNode): JSX.Element => {
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AuthProvider>
                <AxiosInterceptor>
                    <ProfileProvider>
                        <RoleManagementProvider>
                            <CommunityProvider>
                                <MembershipsProvider>
                                    <ExternalMembershipsProvider>
                                        <SubscriptionsProvider>
                                            <RealestatesProvider>
                                                <LeasesProvider>
                                                    <InvoicesProvider>
                                                        <ProductsProvider>
                                                            <MembersProvider>
                                                                <ModalProvider>{component}</ModalProvider>
                                                            </MembersProvider>
                                                        </ProductsProvider>
                                                    </InvoicesProvider>
                                                </LeasesProvider>
                                            </RealestatesProvider>
                                        </SubscriptionsProvider>
                                    </ExternalMembershipsProvider>
                                </MembershipsProvider>
                            </CommunityProvider>
                        </RoleManagementProvider>
                    </ProfileProvider>
                </AxiosInterceptor>
            </AuthProvider>
        </QueryClientProvider>
    );
};
const SiteRoutes: React.FC = () => (
    <StyledEngineProvider injectFirst>
        {customGlobalStyles}
        <ThemeProvider theme={defaultTheme}>
            <LanguageProvider>
                <NotificationProvider>
                    <FeatureFlagProvider>
                        <Notification />
                        <HashRouter>{providerWrapping(<AuthSwitch />)}</HashRouter>
                    </FeatureFlagProvider>
                </NotificationProvider>
            </LanguageProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);

const AuthSwitch: React.FC = () => {
    const { isInitiated, isFetchingOrgs, isFetchingProfile } = React.useContext(ProfileContext);

    return (
        <Switch>
            <PublicRoute exact path="/" component={LoginView} />
            <PublicRoute exact path="/login" component={LoginView} />
            <PublicRoute path="/setPassword/:token" component={SetPasswordView} />
            <PublicRoute path="/reset-password/:token" component={ResetPassword} />
            <PublicRoute path="/unsubscribe/:encoded_email/:token" component={UnsubscribeMailListView} />
            <PublicRoute
                path="/acceptInvitation/:invitationUuid"
                render={() => (
                    <LeasesProvider>
                        <AcceptInvitationView />
                    </LeasesProvider>
                )}
            />

            {!isFetchingProfile && !isFetchingOrgs && isInitiated ? PRIVATE_ROUTES : null}

            <PrivateRoute path="*" component={<PageNotFound />} />
            <PublicRoute path="*" component={PageNotFound} />
        </Switch>
    );
};

const PRIVATE_ROUTES = [
    <PrivateRoute
        key="dashboard"
        path="/dashboard"
        exact
        component={
            <NewsArticleProvider>
                <StartpageView />
            </NewsArticleProvider>
        }
    />,
    <PrivateRoute key="profile" path="/profile" exact component={<ProfileView />} />,
    <PrivateRoute key="contact-us" path="/contact-us" exact component={<ContactUsView />} />,
    <PrivateRoute
        key="news"
        path="/news"
        exact
        component={
            <NewsArticleProvider>
                <NewsView />
            </NewsArticleProvider>
        }
    />,
    <PrivateRoute
        key="tenantDashboard"
        path="/tenant-dashboard"
        exact
        component={
            <NewsArticleProvider>
                <TenantDashboardView />
            </NewsArticleProvider>
        }
    />,
    <PrivateRoute key="tasks" path="/tasks" exact component={<TasksView />} />,
    <PrivateRoute key="services" path="/services" exact component={<ServicesView />} />,
    <PrivateRoute key="realestates" path="/realestates/" exact component={<BuildingsView />} />,
    <PrivateRoute key="realestates-details" path="/realestates/:buildingUuid" component={<BuildingInformation />} />,
    <PrivateRoute key="leases" path="/leases" exact component={<LeasesViewV2 />} />,
    <PrivateRoute key="customer-ledger" path="/customer-ledgers" exact component={<LeaseSelectView />} />,
    <PrivateRoute key="customer-ledger-id" path="/customer-ledgers/:lease" component={<CustomerLedgers />} />,
    <PrivateRoute
        key="arena-dashboard"
        path="/arena-member-dashboard"
        exact
        component={
            <NewsArticleProvider>
                <ArenaMemberDashboard />
            </NewsArticleProvider>
        }
    />,
    <PrivateRoute key="statistics" path="/statistics" exact component={<StatisticsView />} />,
    <PrivateRoute key="memberships" path="/memberships" exact component={<MembershipsView />} />,
    <PrivateRoute key="subscriptions" path="/subscriptions" exact component={<MembershipsView />} />,
    <PrivateRoute
        key="subscription"
        path="/subscriptions/:membershipId/:subscriptionUuid/:tab"
        exact
        component={<SubscriptionView />}
    />,
    <PrivateRoute key="subscriptions-id" path="/subscriptions/:membershipId" exact component={<SubscriptionsView />} />,
    <PrivateRoute key="members" path="/members" exact component={<MembersView />} />,
    <PrivateRoute key="arena-offices" path="/arena-offices" exact component={<MembershipsView />} />,
    <PrivateRoute
        key="arena-offices-id"
        path="/arena-offices/:membershipId"
        component={
            <ArenasProvider>
                <ArenaView />
            </ArenasProvider>
        }
    />,
    <PrivateRoute
        key="arena-info-membership"
        path="/arena-buildings"
        exact
        component={
            <ArenasProvider>
                <ArenaBuildings />
            </ArenasProvider>
        }
    />,
    <PrivateRoute
        key="arena-info-detail"
        path="/arena-buildings/:arenaBuildingUuid"
        component={
            <ArenasProvider>
                <ArenaInformation />
            </ArenasProvider>
        }
    />,
    <PrivateRoute key="representatives" path="/representatives" exact component={<RoleUserManagementView />} />,
    <PrivateRoute
        key="representatives-membership-detail"
        path="/representatives/membership/:membershipId"
        component={<MembershipUserDetailsView />}
    />,
    <PrivateRoute
        key="representatives-lease-detail"
        path="/representatives/lease/:leaseUuid"
        component={<LeaseUserDetailsView />}
    />,
    <PrivateRoute
        key="community-staff-dashboard"
        path="/staff-dashboard"
        exact
        component={
            <NewsArticleProvider>
                <ArenaCommunityStaffDashboardView />
            </NewsArticleProvider>
        }
    />,
    <PrivateRoute key="staff-memberships" path="/staff-memberships" exact component={<MembershipsStaffView />} />,
    <PrivateRoute
        key="staff-membership"
        path={["/staff-memberships/:membershipId/:tab", "/staff-memberships/:membershipId/:tab/:period"]}
        exact
        component={<MembershipView />}
    />,
    <PrivateRoute
        key="staff-externalMemberships"
        path="/staff-external-memberships"
        exact
        component={<ExternalMembershipsView />}
    />,
    <PrivateRoute key="staff-sergel" path="/staff-sergel" exact component={<SergelConferenceView />} />,
    <PrivateRoute key="staff-products" path="/staff-products" exact component={<ProductsView />} />,
    // App purchases route must come before invoicing route
    <PrivateRoute
        key="staff-app-purchases"
        path="/staff-invoicing/app-purchases"
        exact
        component={<InvoiceFileUploadView />}
    />,
    <PrivateRoute
        key="staff-invoice-memberships-orders"
        path={["/staff-invoicing", "/staff-invoicing/:tab", "/staff-invoicing/memberships/:period"]}
        exact
        component={<InvoicingView />}
    />,
    <PrivateRoute
        key="staff-invoice-event-orders"
        path="/staff-invoicing/events/:eventUuid"
        exact
        component={<InvoiceEventOrdersView />}
    />,
    <PrivateRoute
        key="staff-invoice"
        path={[
            // Path with invoiceUuid must be first, otherwise the params isn´t included in the page
            "/staff-invoices/:orderUuid/:invoiceUuid",
            "/staff-invoices/:orderUuid",
        ]}
        component={<InvoiceView />}
    />,
    <PrivateRoute
        key="staff-representatives"
        path="/staff-representatives"
        exact
        component={<StaffRepresentativesView />}
    />,
    <PrivateRoute key="staff-management" path="/staff-management" exact component={<CommunityStaffManagementView />} />,
    <PrivateRoute key="staff-costs" path="/staff-cost-calculations" exact component={<CostCalculationRunsView />} />,
    <PrivateRoute key="events" path="/events" exact component={<EventsView />} />,
];

export default SiteRoutes;

import React from "react";
import { useHistory } from "react-router-dom";
import VKButton from "components/vkButton";
import TextField from "@mui/material/TextField";
import LoginWrapper from "components/loginWrapper";
import { LocalizeText } from "components/localizer";
import Password from "components/password";
import { AuthContext } from "contexts/auth";
import { IAuthorization } from "reducers/auth";
import ForgotPassword from "components/forgotPassword";
import Register2FAMethod from "components/2FA/register2FAMethod";
import { Modal, styled, Typography } from "@mui/material";
import VerifyLogin from "components/2FA/verifyLogin";
import validator from "utils/validator";
import useQueryParams from "utils/hooks/useQueryParams";
import { useActiveFeatureMessage } from "utils/hooks/useActiveFeatureMessage";

interface IPassword {
    password: string;
    username: string;
    showPassword: boolean;
}

export const TwoFABackgroundBox = styled("div")(() => ({
    alignItems: "center",
    margin: "auto",
    width: "75%",
    height: "90%",
    outline: "none",
    "@media (max-height:820px), (max-width:1024px)": {
        "& h2": {
            fontSize: "1rem",
        },
        "& h1, & span": {
            fontSize: "0.75rem",
        },
    },
    overflow: "hidden",
}));

export const FormFields = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const LoginView: React.FC = () => {
    const { login, clearAuthState, isLoggingIn, isLoggedIn, sendAuthenticationSMS } = React.useContext(AuthContext);
    const [values, setValues] = React.useState<IPassword>({
        password: "",
        username: "",
        showPassword: false,
    });
    const [registering2fa, setRegistering2fa] = React.useState<boolean>(false);
    const [verifying, setVerifying] = React.useState<boolean>(false);
    const [authorization, setAuthorization] = React.useState<IAuthorization | null>(null);
    const [currentPhoneNumber, setCurrentPhoneNumber] = React.useState<string>("");
    const featureFlagMessage = useActiveFeatureMessage();

    const history = useHistory();
    const queryParams = useQueryParams();

    React.useEffect(() => {
        if (isLoggedIn) {
            history.push("/dashboard");
        }
        // eslint-disable-next-line
    }, []);

    const setValue = (type: string, prop: string | boolean): void => {
        setValues({ ...values, [type]: prop });
    };

    const validateData = (): boolean => {
        return validator.email(values.username, true);
    };
    const setVerifyingHandler = async (authorizationObject: IAuthorization): Promise<void> => {
        // Prefetch number so that it is defined before showing 2FA window
        if (authorizationObject.hasSMSDevices && !authorizationObject.hasTotpDevices) {
            const number = await sendAuthenticationSMS(authorizationObject.key);
            if (number) {
                setCurrentPhoneNumber(number);
            } else {
                return;
            }
        }
        setAuthorization(authorizationObject);
        setVerifying(true);
    };

    async function createSignInHandler(event: React.SyntheticEvent): Promise<void> {
        event.preventDefault();
        const success = await login(values.username, values.password);
        if (success?.key) {
            const noActiveOTPDevices = !success?.hasTotpDevices && !success.hasSMSDevices;
            if (noActiveOTPDevices) {
                setAuthorization(success);
                setRegistering2fa(true);
            } else {
                setVerifyingHandler(success);
            }
        } else if (Object.keys(success).length > 0) {
            onFinish();
        }
    }

    const onClose = (): void => {
        if (isLoggedIn) {
            onFinish();
        } else {
            clearAuthState();
            setRegistering2fa(false);
            setVerifying(false);
        }
    };

    const onFinish = (): void => {
        setRegistering2fa(false);
        setVerifying(false);

        const redirect = queryParams.get("redirect");
        queryParams.delete("redirect");

        const url = redirect ?? "/dashboard";
        history.push(`${url}?${queryParams.toString()}`);
    };

    const renderDialogs = (): JSX.Element[] | null => {
        if (!authorization) {
            return null;
        }

        return [
            <Modal
                key="register2FA"
                open={registering2fa && !!authorization}
                onClose={onClose}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <TwoFABackgroundBox>
                    <Register2FAMethod onFinish={onFinish} authorizationObject={authorization} />
                </TwoFABackgroundBox>
            </Modal>,
            <Modal
                key="verifyLogin"
                open={!!verifying}
                onClose={onClose}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <div>
                    <VerifyLogin
                        onSuccess={onFinish}
                        authorizationObject={authorization || undefined}
                        phoneNumber={currentPhoneNumber}
                    />
                </div>
            </Modal>,
        ];
    };

    return (
        <LoginWrapper testid="loginView-root">
            <FormFields onSubmit={(e) => createSignInHandler(e)}>
                <TextField
                    size="small"
                    inputProps={{
                        "data-testid": "loginView-email",
                        tabIndex: 1,
                        sx: {
                            boxShadow: "200px 200px 100px white inset",
                        },
                    }}
                    required
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="email"
                    label={<LocalizeText tag="email" />}
                    id="email"
                    autoComplete="email"
                    onChange={(e) => setValue("username", e.target.value)}
                    error={!validator.email(values.username, true)}
                    helperText={!validator.email(values.username, true) && <LocalizeText tag="errorEmail" />}
                />
                <Password onChange={(value: string) => setValue("password", value)} />
                <VKButton
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    tabIndex={3}
                    data-testid="loginView-submitButton"
                    type="submit"
                    tag="login"
                    template="primary"
                    isLoading={isLoggingIn}
                    disabled={!validateData()}
                    onClick={(e) => createSignInHandler(e)}
                />
            </FormFields>
            <ForgotPassword />
            <Typography variant="body1" mt={"2rem"} color="red">
                {featureFlagMessage("maintenance")
                    .split("\\n")
                    .map((item, idx) => {
                        return (
                            <span key={idx}>
                                {item}
                                <br />
                                <br />
                            </span>
                        );
                    })}
            </Typography>

            {renderDialogs()}
        </LoginWrapper>
    );
};

export default LoginView;

import React, { FC, useMemo } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { prettifyOrgNumber } from "utils/helpers";
import { useHistory } from "react-router-dom";
import { LocalizeText } from "components/localizer";
import { InvoiceOrderStatus } from "components/invoices/invoiceOrderStatus";
import { THeadCell } from "utils/types";
import { TLanguageTag } from "contexts/language";
import { InvoicesTable } from "./invoicesTable";
import { IUsePaginationStore } from "utils/usePaginationStore";

const invoiceOrdersHeadCells: THeadCell[] = [
    { id: "customer_number", sortId: "customer_number", label: <LocalizeText tag="contractNumber" /> },
    {
        id: "company_name",
        sortId: "company_name",
        label: <LocalizeText tag="name" />,
    },
    {
        id: "org_number",
        sortId: "org_number",
        label: <LocalizeText tag="orgNumberShort" />,
    },
    { id: "payment_status", label: <LocalizeText tag="status" /> },
];

interface IProps {
    pagination: IUsePaginationStore<"invoice-orders">;
}
export const InvoiceOrdersTable: FC<IProps> = ({ pagination }) => {
    const history = useHistory();

    const rows = useMemo<IRow[]>(
        () =>
            pagination.results.map((invoiceOrder) => {
                const { uuid, company_name, reg_number, currency_code, customer_number, invoices } = invoiceOrder;

                const onClick = invoices.length === 0 ? () => history.push(`/staff-invoices/${uuid}`) : undefined;

                return {
                    onClick,
                    data: [
                        customer_number || "-",
                        company_name || "-",
                        reg_number ? prettifyOrgNumber(reg_number) : "-",
                        <InvoiceOrderStatus key={`status_${uuid}`} order={invoiceOrder} shortVersion={true} />,
                    ],
                    collapsibleContent:
                        invoices.length > 0 ? (
                            <InvoicesTable
                                key={`invoices_${uuid}`}
                                orderUuid={uuid}
                                invoices={invoices.slice().sort((a, b) => a.type.localeCompare(b.type))}
                                currency={currency_code as TLanguageTag}
                            />
                        ) : undefined,
                };
            }),
        [pagination.results, history]
    );

    return (
        <TableComponent
            headCells={invoiceOrdersHeadCells}
            isLoading={pagination.query.isFetching}
            rows={rows}
            skeletonVariant="options"
            pagination={pagination}
            collapsible
        />
    );
};
